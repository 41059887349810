import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { withIntl } from '../../utils/withIntl';
import { TermsModalType } from '../../utils/types';

import BasicModal from '../../admin/shared/BasicModal';
import {
  closeTermsModal,
} from '../ModalActions';
import TermsContainer from '../../consumer/shared/TermsContainer';

const TermsModal = ({ t }) => {
  const showTermsModal = useSelector(state => state.modals.showTermsModal);
  const dispatch = useDispatch();

  switch (showTermsModal.modalType) {
    case TermsModalType.ClientTerms:
      return (
        <BasicModal
          closeModal={() => dispatch(closeTermsModal())}
          show={showTermsModal.showModal}
          size="lg"
          title={t('nav.client_terms')}
        >
          <TermsContainer termsType="client_terms" />
        </BasicModal>
      );
    case TermsModalType.Privacy:
      return (
        <BasicModal
          closeModal={() => dispatch(closeTermsModal())}
          show={showTermsModal.showModal}
          size="lg"
          title={t('nav.privacy')}
        >
          <TermsContainer termsType="privacy" />
        </BasicModal>
      );
    case TermsModalType.Giveaway:
      return (
        <BasicModal
          closeModal={() => dispatch(closeTermsModal())}
          show={showTermsModal.showModal}
          size="lg"
          title={t('nav.giveaway_terms')}
        >
          <TermsContainer termsType="giveawayTerms" />
        </BasicModal>
      );
    case TermsModalType.Accessibility:
      return (
        <BasicModal
          closeModal={() => dispatch(closeTermsModal())}
          show={showTermsModal.showModal}
          size="lg"
          title={t('nav.web_accessibility')}
        >
          <TermsContainer termsType="web_accessibility" />
        </BasicModal>
      );
    default:
      return null;
  }
};

TermsModal.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withIntl(TermsModal);
